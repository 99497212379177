import { mapGetters } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
export const localityValidationMixin = {
  mixins: [utilMixin],
  methods: {
    checkLocality(value) {
      if (this.getLocalities === null) {
        return true;
      }
      if (value === null || value.length === 0) {
        return false;
      }
      const currentValue = value.toString().toLowerCase();
      if (currentValue.length > 0) {
        let inputValid = false;
        this.getLocalities.forEach(locality => {
          const localityStr = this.formatLocality(locality).toLowerCase();
          inputValid |= localityStr === currentValue;
        });
        return inputValid;
      }
    }
  },
  computed: {
    ...mapGetters(["getLocalities"])
  }
};
