<template>
  <!--begin::Card-->
  <div v-if="isLoaded" class="card card-custom">
    <!-- begin::Form-->
    <b-form class="form" v-on:submit.prevent="save">
      <!--begin::Body-->
      <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
        <div class="row">
          <div class="offset-xxl-2 col-xxl-8">
            <h4 class="mb-10 font-weight-bold text-dark">
              Firmenlogo
            </h4>
            <div class="row" v-if="!company.has_paid_subscription">
              <div class="col-xl-12">
                <b-jumbotron>
                  <template v-slot:lead>
                    Sie müssen ein kostenpflichtes Abonement abschliessen um
                    ihre Firmenlogo hochzuladen. Das Hochladen eines Firmenlogo
                    ist nicht im Grundpreis des Jahresabonement enthalten, kann
                    jedoch als Option hinzufügen.
                  </template>
                  <router-link
                    to="/adjust-subscription"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <b-button variant="primary" @click="navigate"
                      >Abonement abschliessen
                    </b-button>
                  </router-link>
                  <!--                  <b-button variant="primary" href="#">Abonement anpassen</b-button>-->
                </b-jumbotron>
              </div>
            </div>
            <div class="row" v-else-if="!company.has_logo">
              <div class="col-xl-12">
                <b-jumbotron>
                  <template v-slot:lead>
                    Die Option um Ihr Logo hochzuladen ist in Ihrem Abonment
                    nicht enthalten.
                  </template>
                  <hr class="my-4" />
                  <p>
                    Sie können diese Option aber jederzeit zu Ihrem Abonoment
                    hinzufügen.
                  </p>
                  <router-link
                    to="/add-subscription-option"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <b-button variant="primary" @click="navigate"
                      >Optionen hinzufügen
                    </b-button>
                  </router-link>
                  <!--                  <b-button variant="primary" href="#">Abonement anpassen</b-button>-->
                </b-jumbotron>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-xl-6">
                <div class="image-input image-input-outline">
                  <b-img
                    thumbnail
                    fluid
                    :src="companyImage"
                    alt="Company Logo"
                  ></b-img>
                  <!--                  <span-->
                  <!--                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"-->
                  <!--                    data-action="cancel"-->
                  <!--                    data-toggle="tooltip"-->
                  <!--                    title="Cancel avatar"-->
                  <!--                  >-->
                  <!--                    <i class="ki ki-bold-close icon-xs text-muted"></i>-->
                  <!--                  </span>-->
                  <span
                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="remove"
                    data-toggle="tooltip"
                    title="Remove avatar"
                    @click="company.logo = null"
                  >
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-xl-6">
                <b-form-file
                  placeholder="Bild auswählen..."
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  @change="onFileChange($event)"
                ></b-form-file>
              </div>
            </div>
            <h4 class="mb-10 mt-10 font-weight-bold text-dark">
              Adresse
            </h4>
            <ValidatedInput
              name="Firmenname"
              v-model="company.company_name"
              :v="$v.company.company_name"
              errorMessage="Das ist ein Pflichtfeld."
            />
            <div class="row">
              <div class="col-xl-6">
                <ValidatedInput
                  name="Strasse"
                  v-model="company.street"
                  :v="$v.company.street"
                  errorMessage="Das ist ein Pflichtfeld."
                />
              </div>
              <div class="col-xl-6">
                <ValidatedInput
                  name="Nummer"
                  v-model="company.street_nr"
                  :v="$v.company.street_nr"
                  errorMessage="Das ist ein Pflichtfeld."
                />
              </div>
            </div>
            <div class="form-group">
              <label>Ortschaft</label>
              <b-form-input
                class="form-control form-control-lg form-control-solid"
                list="localities-list-filter-id"
                placeholder="Ortschaft"
                v-model="$v.company.locality.$model"
                :state="validateState('locality')"
                aria-describedby="input-locality-feedback"
              ></b-form-input>
              <datalist id="localities-list-filter-id">
                <option
                  v-for="locality in getLocalities"
                  v-bind:key="locality.id"
                  >{{ formatLocality(locality) }}
                </option>
              </datalist>
              <b-form-invalid-feedback id="input-locality-feedback">
                Das ist keine gültige Ortschaft.
              </b-form-invalid-feedback>
            </div>
            <h4 class="mb-10 mt-10 font-weight-bold text-dark">
              Kontakinformationen
            </h4>
            <div class="row">
              <div class="col-xl-6">
                <ValidatedInput
                  name="Mail"
                  v-model="company.mail"
                  :v="$v.company.mail"
                  errorMessage="Das ist ein Pflichtfeld und muss eine gültige Email Adresse sein."
                />
              </div>
              <div class="col-xl-6">
                <ValidatedInput
                  name="Telefon"
                  v-model="company.phone"
                  :v="$v.company.phone"
                  errorMessage="Das ist ein Pflichtfeld."
                  :helpText="'Bsp: ' + phone_example"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6">
                <ValidatedInput
                  name="Mobile"
                  v-model="company.mobile"
                  :v="$v.company.mobile"
                  errorMessage="Das ist ein Pflichtfeld."
                  :helpText="'Bsp: ' + phone_example"
                />
              </div>
              <div class="col-xl-6">
                <ValidatedInput
                  name="Fax"
                  v-model="company.fax"
                  :v="$v.company.fax"
                  errorMessage="Das ist ein Pflichtfeld."
                  :helpText="'Bsp: ' + phone_example"
                />
              </div>
            </div>
            <ValidatedInput
              name="Website"
              v-model="company.website"
              :v="$v.company.website"
              errorMessage="Das ist ein Pflichtfeld."
            />
            <div class="form-group">
              <label>Korrespondenzsprache</label>
              <b-form-checkbox-group
                v-model="company.languages_of_correspondence"
                :state="validateState('languages_of_correspondence')"
              >
                <b-form-checkbox
                  v-for="language in getLanguages"
                  :key="language.shortcut"
                  :value="language.id"
                  name="language-selection"
                  inline
                >
                  {{ language.language }}
                </b-form-checkbox>
                <b-form-invalid-feedback
                  :state="validateState('languages_of_correspondence')"
                >
                  Wählen Sie mindestens eine Sprache aus.
                </b-form-invalid-feedback>
              </b-form-checkbox-group>
            </div>
            <!--begin: Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2"></div>
              <div>
                <button
                  class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-submit"
                >
                  Speichern
                </button>
              </div>
            </div>
            <!--end: Actions -->
          </div>
        </div>
      </div>
      <!--end::Body-->
    </b-form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import ValidatedInput from "@/components/ValidatedInput";
import { phoneNumberValidationMixin } from "@/mixins/phoneNumberValidationMixin";
import { localityValidationMixin } from "@/mixins/localityValidationMixin";
import { alertMixin } from "@/mixins/alertMixin";
import { utilMixin } from "@/mixins/utilMixin";
import { LOAD_LANGUAGES } from "@/store/languages.module";
import { LOAD_USER_COMPANY, UPDATE_USER_COMPANY } from "@/store/company.module";
import { LOAD_LOCALITIES } from "@/store/localities.module";

function phoneNumberValidation(value, vm) {
  return this.validatePhoneNumber(value, vm, "phone", false);
}

function mobileNumberValidation(value, vm) {
  return this.validatePhoneNumber(value, vm, "mobile", true);
}

function faxNumberValidation(value, vm) {
  return this.validatePhoneNumber(value, vm, "fax", true);
}

function localityValidation(value) {
  return this.checkLocality(value);
}

export default {
  components: { ValidatedInput },
  mixins: [
    validationMixin,
    phoneNumberValidationMixin,
    localityValidationMixin,
    alertMixin,
    utilMixin
  ],
  name: "Company",
  data() {
    return {
      company: {},
      default_image: "media/no_image.jpg",
      isLoaded: false
    };
  },
  validations: {
    company: {
      company_name: {
        required
      },
      street: {
        required
      },
      street_nr: {
        required
      },
      locality: {
        required,
        localityValidation
      },
      mail: {
        required,
        email
      },
      mobile: {
        mobileNumberValidation
      },
      phone: {
        phoneNumberValidation
      },
      fax: {
        faxNumberValidation
      },
      website: {},
      languages_of_correspondence: {
        required
      }
    }
  },
  mounted() {
    this.loadCompany();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.company[name];
      return $dirty ? !$error : null;
    },
    async loadCompany() {
      // Load first the languages before the company is loaded
      await Promise.all([
        this.$store.dispatch(LOAD_LANGUAGES),
        this.$store.dispatch(LOAD_LOCALITIES),
        this.$store.dispatch(LOAD_USER_COMPANY)
      ]).then(() => {
        this.company = this.getUserCompany;
        this.setLocality();
      });
      this.isLoaded = true;
    },
    save() {
      this.$v.company.$touch();
      if (this.$v.company.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      // Replace the locality name with the corresponding id
      const payload = JSON.parse(JSON.stringify(this.company));
      payload.locality = this.getLocalityIdForName(this.company.locality);

      this.$store.dispatch(UPDATE_USER_COMPANY, payload).then(response => {
        this.company = response.data;
        this.setLocality();
        this.showChangesSavedAlert();
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.company.logo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setLocality() {
      let locality = this.getLocalityNameForId(this.company.locality);
      if (locality) {
        this.company.locality = locality;
      }
    }
  },
  computed: {
    ...mapGetters(["getUserCompany", "getLanguages", "getLocalities"]),
    companyImage() {
      return this.company.logo != null ? this.company.logo : this.default_image;
    }
  }
};
</script>
